import smoothscroll from 'smoothscroll-polyfill';
import SmoothScroll from 'smooth-scroll';
import { recruit } from './modules/recruit';
recruit();

smoothscroll.polyfill();

const scroll = new SmoothScroll('a[href*="#"]', {
  header: '#header',
  speed: 300,
  updateURL: false,
});
