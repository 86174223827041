export function recruit() {
  const recruitSelect = document.querySelectorAll('.js-recruitSelect');
  const recruitSelectList = Array.prototype.slice.call(recruitSelect, 0);

  // ページ訪問時
  window.addEventListener('load', () => {
    itemSearch();
  });

  recruitSelectList.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      if (targetBox.classList.contains('js-recruitJobSelect')) {
        jobToggle(targetBox);
      }

      if (targetBox.classList.contains('js-recruitFacilitySelect')) {
        facilityToggle(targetBox);
      }

      itemSearch();
    });
  });
}

/* 一覧の絞り込み処理 */
function itemSearch() {
  // 絞り込みボタン（職種と施設）
  const recruitJobSelect = document.querySelectorAll('.js-recruitJobSelect');
  const recruitJobSelectList = Array.prototype.slice.call(recruitJobSelect, 0);
  const recruitFacilitySelect = document.querySelectorAll('.js-recruitFacilitySelect');
  const recruitFacilitySelectList = Array.prototype.slice.call(recruitFacilitySelect, 0);

  // 一覧
  const recruitItem = document.querySelectorAll('.js-recruitItem');
  const recruitItemList = Array.prototype.slice.call(recruitItem, 0);

  let selectJobType = '';
  let selectFacilityType = '';

  recruitJobSelectList.forEach(targetBox => {
    if (targetBox.classList.contains('active')) {
      if (targetBox.classList.contains('js-recruitJobSelect')) {
        selectJobType = targetBox.getAttribute('data-selectJob');
      }
    }
  });

  recruitFacilitySelectList.forEach(targetBox => {
    if (targetBox.classList.contains('active')) {
      if (targetBox.classList.contains('js-recruitFacilitySelect')) {
        selectFacilityType = targetBox.getAttribute('data-selectFacility');
      }
    }
  });

  recruitItemList.forEach(targetBox => {
    const targetJobData = targetBox.getAttribute('data-job');
    const targetFacilityData = targetBox.getAttribute('data-facility');

    if (selectJobType != '' && selectFacilityType == '') {
      if (selectJobType == targetJobData) {
        targetBox.classList.add('is-view');
      } else {
        targetBox.classList.remove('is-view');
      }
    } else if (selectJobType == '' && selectFacilityType != '') {
      if (selectFacilityType == targetFacilityData) {
        targetBox.classList.add('is-view');
      } else {
        targetBox.classList.remove('is-view');
      }
    } else if (selectJobType != '' && selectFacilityType != '') {
      if (selectJobType == targetJobData || selectFacilityType == targetFacilityData) {
        targetBox.classList.add('is-view');
      } else {
        targetBox.classList.remove('is-view');
      }
    } else {
      targetBox.classList.remove('is-view');
    }
  });
}
/* end 一覧の絞り込み処理 */

/* 職種で絞り込む */
function jobToggle(jobItem) {
  const recruitJobSelect = document.querySelectorAll('.js-recruitJobSelect');
  const recruitJobSelectList = Array.prototype.slice.call(recruitJobSelect, 0);

  if (jobItem.classList.contains('active')) {
    console.log('remove');
    jobItem.classList.remove('active');
  } else {
    for (let i = 0; i < recruitJobSelectList.length; i++) {
      recruitJobSelectList[i].classList.remove('active');
    }
    console.log('add');
    jobItem.classList.add('active');
  }
}
/* end 職種で絞り込む */

/* 施設で絞り込む */
function facilityToggle(facilityItem) {
  const recruitFacilitySelect = document.querySelectorAll('.js-recruitFacilitySelect');
  const recruitFacilitySelectList = Array.prototype.slice.call(recruitFacilitySelect, 0);

  if (facilityItem.classList.contains('active')) {
    facilityItem.classList.remove('active');
  } else {
    for (let i = 0; i < recruitFacilitySelectList.length; i++) {
      recruitFacilitySelectList[i].classList.remove('active');
    }
    facilityItem.classList.add('active');
  }
}
/* end 施設で絞り込む */
